const Tabs = () => {
    TabsDefault();
    TabsForm();
    TabsFooter();

}

const TabsForm = () => {
    let tabs = [...document.querySelectorAll('.tabs')]
    if (!tabs) {
        return
    }

    tabs.forEach(tab => {
        let labels = [...tab.querySelectorAll('.tabs__labels label')]
        let items = [...tab.querySelectorAll('.tabs__items .tab')]
        labels.forEach(element => {
            element.addEventListener('click', () => {
                let dataLabel = element.dataset.tab;
                labels.forEach(elementLabel => {
                    elementLabel.classList.remove('is--active');
                })

                element.classList.add('is--active')

                items.forEach(elementItem => {
                    elementItem.classList.remove('is--active');
                    if (elementItem.dataset.tab == dataLabel) {
                        elementItem.classList.add('is--active')
                    }
                })
            })
        })
    })
}

const TabsDefault = () => {
    let tabs = [...document.querySelectorAll('.tabs__default')];
    if (!tabs) {
        return;
    }

    tabs.forEach(element => {
        let labelHolder = element.querySelector('.tabs__labels');
        let tabsFooter = element.querySelector('.tabs__footer');
        let TabsDefault = element.querySelector('.tabs__labels--default');
        let itemHolder = element.querySelector('.tabs__items');
        let labels = [...element.querySelectorAll('.label__item')]
        let items = [...element.querySelectorAll('.tab')];
        let mobileItems = element.querySelector('.tabs__labels__mobile');

        labels.forEach(element => {
            labelHolder.appendChild(element)
        });

        items.forEach(element => {
            itemHolder.appendChild(element)
        });

        element.querySelector('.tabs__labels label:first-child').classList.add('is--active');
        element.querySelector('.tabs__items .tab:first-child').classList.add('is--active');


        TabsDefault.addEventListener('click', (e) => {
            if (tabsFooter) {
                if (e.target !== e.currentTarget) return;
            }
            TabsDefault.classList.toggle('is--active')
        })
    });
}

const TabsFooter = () => {
    let tabs = [...document.querySelectorAll('.tabs__has-footer')];

    if (!tabs) {
        return
    }

    tabs.forEach(element => {
        var text = [];
        let TabsDefault = element.querySelector('.tabs__labels--default');
        let labels = [...element.querySelectorAll('.label__item')];
        let tab1 = element.querySelector('.tab__footer__1 span:not(.mobile)');
        let tab2 = element.querySelector('.tab__footer__2 span:not(.mobile)');
        let footerTabs = element.querySelectorAll('.btn');
        var i = 0;

        labels.forEach(label => {
            label.dataset.footer = i
            text.push(label.querySelector('.title').textContent)
            i++;


        });

        labels.forEach((label, i) => {
            label.addEventListener('click', () => {
                TabsDefault.classList.remove('is--active');
                let count = label.dataset.footer;

                count = parseInt(count);

                tab1.parentElement.dataset.footer = count - 1;
                tab2.parentElement.dataset.footer = count + 1;


                tab1.parentElement.style.display = 'inline-flex'
                tab2.parentElement.style.display = 'inline-flex'

        

                if (count == 0) {
                    tab2.textContent = text[1]
                } else if (count == labels.length - 1) {
                    tab2.textContent = text[0]
                } else {
                    tab2.textContent = text[count + 1]
                }

                if (count == labels.length - 1) {
                    tab1.textContent = text[labels.length - 2]
                } else if (count == 0) {
                    tab1.textContent = text[text.length - 1]
                } else {
                    tab1.textContent = text[count - 1]
                }


            })
        });

        tab2.textContent = text[1]
        tab1.textContent = text[text.length - 1]


        footerTabs.forEach(btn => {
            btn.addEventListener('click', () => {
                let data = btn.dataset.footer;
                if (data == -1) {
                    labels[labels.length - 1].click();
                } else if (data == labels.length) {
                    labels[0].click();
                } 
                else{
                    labels[data].click();
                }


            })
        });

    });

}


Tabs()