

const Programm = () => {
    let containers = [...document.querySelectorAll('.program__wrapper')];

    if (!containers) {
        return;
    }

    containers.forEach(container => {
        let elements = [...container.querySelectorAll('.program__element')];
        elements[0].classList.add('is--active');
        container.addEventListener('scroll', function () {
            var scrollTop = container.scrollTop;


            elements.forEach((item, i) => {
               
                let offsetItem = item.offsetTop;
                if (scrollTop + 50 < offsetItem) {
                    item.classList.remove('is--active')
                } else {
                    item.classList.add('is--active')
                }


            });

        })


    });
};

Programm();