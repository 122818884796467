import Cookie from '../Helpers/Cookie';

const CookieNotice = () => {
    let buttons = document.querySelectorAll('.cookieNotice button');
    let container = document.querySelector('.cookieNotice');

    let acceptBtn = document.querySelector('.cookieNotice__action .actions .accept a');
    let denyBtn = document.querySelector('.cookieNotice__action .actions .deny a');

    buttons.forEach(button => {
        button.addEventListener('click', () => {

            if (button.classList.contains('btn-accept')) {
                acceptBtn.click();
            } else if ( button.classList.contains('btn-deny')) {
                denyBtn.click();
            }

            Cookie.set('cookienotice', '1', 99999);
            container.classList.remove('is--active');
        })
    });



    let getCookie = Cookie.get('cookienotice');

    if (getCookie != 1) {

        container.classList.add('is--active');
    }
}

CookieNotice();