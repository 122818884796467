import Flickity from 'flickity';

const Hero = () => {
    let hero = document.querySelector('.hero__wrapper');
    let heroElements = [...document.querySelectorAll('.hero__container')];
    let element = document.querySelector('.mastheader__font-size');

    if (!heroElements || heroElements.length == 1) {
        return
    }

    var args = {
        cellAlign: 'center',
        contain: true,
        pageDots: true,
        prevNextButtons: false,
        adaptiveHeight: false,
        wrapAround: true,
        contain: true,
    }
    var flkty = new Flickity(hero, args);

    element.addEventListener('click', () => {
        setTimeout(() => {
            flkty.resize()
        }, 250)
        
    })
}

Hero();