const Dropdown = () => {
    let inputs = [...document.querySelectorAll('.dropdown__item')];
    let singleInput = document.querySelector('.dropdown__item');

    if (!inputs) {
        return
    }
    

    if ( singleInput ) {
        if ( singleInput.closest('.information-list') ) {
            let container = singleInput.closest('.information-list');
            let image = container.querySelector('.information-list__image  img');    
            let height = image.clientHeight;
            image.style.height = height + 'px'
        }
    }

    

    inputs.forEach(element => {
        let input = element.querySelector('input');
        input.addEventListener('click', () => {
            inputs.forEach(subelement => {                
                let subInput = subelement.querySelector('input');
                if (subInput.checked) {
                    subInput.click()
                }
            });
        })
    });
}

Dropdown();