const Space = () => {
    let elements = [...document.querySelectorAll('.wp-block-spacer')];
    if ( !elements) {
        return;
    }


    const setSpace = () => {
        elements.forEach(item => {
            var value = getComputedStyle(item).height.replace('px', '');
            value = parseInt(value);
            if ( value > 90 ) {
                value = value/1.5;    
                item.style.height = value+'px';
            }

        });
    }

    const spaceInit = () => {
        if ( window.innerWidth < 768 ) {
            setSpace();
        }
    }

    spaceInit();
}

Space();