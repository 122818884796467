import Cookie from "../Helpers/Cookie";
import Aos from "aos";

const Navigation = () => {
    NavigationScroll();
    NavigationFontSize();
}

const NavigationScroll = () => {
    let lastScrollTop = 0;
    let header = document.querySelector('.mastheader');

    window.addEventListener("scroll", function () {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop) {
            if (st > 20) {
                header.classList.add('is--active');
            } else {
                header.classList.remove('is--active');
            }
            // downscroll code
        }
       
    }, false);
}

const NavigationFontSize = () => {
    let element = document.querySelector('.mastheader__font-size');
    let htmlDom = document.querySelector('html');
    element.addEventListener('click', () => {
        
        element.classList.toggle('is--active')
        if ( element.classList.contains('is--active') ) {
            htmlDom.style.fontSize = "81.25%";
            Cookie.set('fontsize', '1', 999999);
            Aos.refresh();
        } else {
            htmlDom.style.fontSize = "62.5%";
            Cookie.set('fontsize', '0', 999999);
            Aos.refresh();
  
        }
    })

    console.log(Cookie.get('fontsize'))
    if ( Cookie.get('fontsize') ) {
        if ( Cookie.get('fontsize') == '1' ) {
            element.classList.add('is--active')
            htmlDom.style.fontSize = "81.25%";
        }
    }
 
}

Navigation();